/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        var clipboard_success = function(e) {
          $(e.trigger).text('Copied!');
          e.clearSelection();
        };

        new Clipboard('.btn-clipboard').on('success', clipboard_success);
        new Clipboard('.btn-clipboard-dynamic', {
          target: function(trigger) {
            return trigger.nextElementSibling;
            }
        }).on('success', clipboard_success);
        new Clipboard('.btn-clipboard-dynamic-input', {
          target: function(trigger) {
            var target = $(trigger).parents('.input-group').find('input')[0];
            return target;
            }
        }).on('success', clipboard_success);

        $('.promo-link-master').each(function(i){
          var url;
          $this = $(this);
          switch(this.tagName.toLowerCase()){
            case 'input': url = $this.val();
              break;
            case 'a':url = $this.prop('href');
              break;
            default: url = $this.text();
          }

          $('.promo-link[data-promo='+$this.data('promo')+']').each(function(){
            $this = $(this);
            switch(this.tagName.toLowerCase()){
              case 'input': $this.val(url);
                break;
              case 'a':$this.prop('href', url);
                break;
              default: $this.text(url);
            }
          });
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.button.button-primary').removeClass('button button-primary').addClass('btn btn-default btn-lg');
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
